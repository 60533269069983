<template>
  <div class="container global-list-main-container">
    <div class="upper-global-title">
      <span class=""> Presentaciones </span>
    </div>

    <div class="upper-global-hint">
      <b-icon icon="lightbulb-outline" type="is-primary"> </b-icon>
      <span class="global-form-hint-color"> Recuerda: </span> {{ hint }}
    </div>
    <b-field grouped group-multiline class="global-lists-controls">
      <div class="control special-margin">
        <b-button
          type="is-primary"
          @click="startImport()"
          v-if="
            companyPaymentPlanModules &&
            companyPaymentPlanModules.main.importPresentations === 'S' &&
            permissions &&
            permissions.main.importPresentations === 'S'
          "
          >Importar presentaciones</b-button
        >
      </div>
      <div class="control special-margin">
        <b-button
          type="is-primary"
          @click="startExport()"
          v-if="
            companyPaymentPlanModules &&
            companyPaymentPlanModules.main.importPresentations === 'S' &&
            permissions &&
            permissions.main.importPresentations === 'S'
          "
          >Exportar presentaciones</b-button
        >
      </div>
      <div class="control special-margin">
        <b-button
          type="is-primary"
          @click="reloadInformation()"
          :loading="refreshLoading"
          >Refrescar información</b-button
        >
      </div>
    </b-field>

    <div class="global-lists-search">
      <div class="global-lists-filters-container"></div>
      <div class="global-lists-filters-columns">
        <b-checkbox
          v-for="singleColumn in columns"
          v-bind:key="singleColumn.label"
          v-model="singleColumn.display"
        >
          {{ singleColumn.label }}
        </b-checkbox>
      </div>
    </div>

    <div class="table-header">
      Cantidad de Artículos: <b>{{ articles.length }}</b>
    </div>
    <b-table
      pagination-position="both"
      :data="articles"
      :striped="true"
      :bordered="true"
      :hoverable="true"
      :checked-rows.sync="checkedArticles"
      :current-page="currentPage"
      :selected.sync="selected"
      ref="Articles"
      paginated
      :per-page="perPage"
      :opened-detailed="defaultOpenedDetails"
      @details-open="
        (row) =>
          $buefy.toast.open({
            message: `Información de ${row.CLAVE_ART}`,
            type: 'is-success',
            position: 'is-bottom',
          })
      "
      detailed
      detail-key="_id"
      sort-icon="arrow-up"
      sort-icon-size="is-small"
      default-sort-direction="asc"
      checkbox-position="left"
      default-sort="CLAVE_ART"
    >
      <template #empty>
        <div class="has-text-centered">No hay articulos</div>
      </template>

      <template slot-scope="props">
        <b-table-column
          v-for="column in columns"
          searchable
          sortable
          v-bind="column"
          :visible="column.display"
          :key="column.field"
          :label="column.label"
        >
          <span v-if="!checkIfValid(props.row[column.field])">{{
            "Sin información"
          }}</span>

          <span> {{ props.row[column.field] }}</span>
        </b-table-column>
      </template>

      <template slot="detail" slot-scope="props">
        <article class="media">
          <figure class="media-left">
            <p class="image is-64x64"></p>
          </figure>
          <div class="content">
            <div
              v-for="singlePresentation in props.row.PRESENTACIONES"
              :key="singlePresentation._id"
              class="single-articles-prices special-margin"
            >
              <h3 class="title-expandable-item">
                <strong>Presentación </strong>
                <strong
                  ><span class="global-form-hint-color">{{
                    singlePresentation.NOMBRE
                  }}</span>
                </strong>
              </h3>

              <b-field grouped class="special-margin">
                <b-field
                  label="Nombre de la presentación (Requerido)"
                  expanded
                  label-position="inside"
                >
                  <b-input
                    placeholder="Ejemplo: Marca 1"
                    expanded
                    icon="numeric-1-circle-outline"
                    v-model="singlePresentation.NOMBRE"
                  ></b-input>
                </b-field>
              </b-field>

              <b-field grouped class="special-margin">
                <b-field
                  label="Es más grande que la unidad base (Requerido)"
                  expanded
                  label-position="inside"
                  class="required-input"
                >
                  <b-select
                    placeholder="Ejemplo: Metro"
                    icon="perspective-more"
                    expanded
                    v-model="singlePresentation.ESMAYOR"
                  >
                    <option value="" disabled>Medida</option>
                    <option value="S">Si</option>
                    <option value="N">No</option>
                  </b-select>
                </b-field>
              </b-field>

              <b-field grouped class="special-margin">
                <b-field
                  label="Medida base del artículo (Requerido)"
                  expanded
                  v-if="props.row.UNIDAD_EMP"
                  label-position="inside"
                  class="required-input"
                >
                  <b-select
                    placeholder="Ejemplo: Metro"
                    icon="tape-measure"
                    expanded
                    v-model="props.row.UNIDAD_EMP._id"
                    disabled
                  >
                    <option value="" disabled>Medida</option>
                    <option
                      v-for="option in measures"
                      :value="option._id"
                      :key="option.CLAVE_MED"
                    >
                      {{ option.DESCRIP_MED }}
                    </option>
                  </b-select>
                </b-field>

                <b-field
                  label="Medida de la presentación (Requerido)"
                  expanded
                  label-position="inside"
                  class="required-input"
                >
                  <b-select
                    placeholder="Ejemplo: Metro"
                    icon="tape-measure"
                    expanded
                    v-model="singlePresentation.MEDIDA"
                  >
                    <option value="" disabled>Medida</option>
                    <option
                      v-for="option in measures"
                      :value="option._id"
                      :key="option._id"
                    >
                      {{ option.DESCRIP_MED }}
                    </option>
                  </b-select>
                </b-field>
              </b-field>

              <b-field grouped class="special-margin">
                <b-field
                  label="Valor de medida base del artículo (Requerido)"
                  expanded
                  label-position="inside"
                >
                  <b-input
                    placeholder="Ejemplo: 40"
                    expanded
                    icon="numeric-1-circle-outline"
                    type="number"
                    step="any"
                    v-model="singlePresentation.FACTOR1"
                  ></b-input>
                </b-field>

                <b-field
                  label="Equivalencia en medidas base (Requerido)"
                  expanded
                  label-position="inside"
                >
                  <b-input
                    placeholder="Ejemplo: 40"
                    expanded
                    icon="numeric-2-circle-outline"
                    type="number"
                    step="any"
                    v-model="singlePresentation.FACTOR2"
                  ></b-input>
                </b-field>
              </b-field>

              <div class="add-global-controls">
                <b-button
                  type="is-primary"
                  @click="editPresentation(singlePresentation, props.row._id)"
                  >Actualizar presentación</b-button
                >
              </div>
            </div>
            <div class="controls">
              <b-button
                type="is-primary"
                @click="openAddPresentation(props.row)"
                >Agregar presentación para artículo</b-button
              >
            </div>
          </div>
        </article>
      </template>
    </b-table>
  </div>
</template>

<script>
import moment from "moment";
import AddArticlePresentation from "./AddArticlePresentation";
import ImportFileModal from "../../Global/components/ImportFileModal";

export default {
  name: "ArticlesPresentation",
  data() {
    //Proveedores
    return {
      perPage: 50,
      checkedArticles: [],
      selected: {},
      currentPage: 1,
      defaultOpenedDetails: [],
      columns: [
        {
          field: "CLAVE_ART",
          label: "Clave de articulo",
          sortable: true,
          searchable: true,
          display: true,
        },
        {
          field: "DESCRIPCION",
          label: "Descripción",
          sortable: true,
          searchable: true,
          display: true,
        },
        {
          field: "lastSaleFormat",
          label: "Ultima venta",
          sortable: true,
          searchable: true,
          display: true,
        },
      ],
      refreshLoading: false,
    };
  },
  mounted() {
    this.$store.dispatch("CHANGEACTIVE", "presentations");
  },
  methods: {
    openAddPresentation(article) {
      this.reloadInformation();
      this.$buefy.modal.open({
        canCancel: ["x"],
        parent: this,
        component: AddArticlePresentation,
        props: {
          article: article,
        },
        hasModalCard: false,
        customClass: "primary-modal-class",
        trapFocus: true,
        fullScreen: false,
        destroyOnHide: true,
      });
    },
    async editPresentation(presentation, articleId) {
      try {
        this.reloadInformation();
        let response = await this.$store.dispatch("EDITARTICLEPRESENTATION", {
          presentation: { ...presentation, MEDIDA: presentation.MEDIDA },
          articleId,
        });
        if (
          !presentation.NOMBRE ||
          !presentation.MEDIDA ||
          !presentation.FACTOR1 ||
          !presentation.FACTOR2
        ) {
          this.$buefy.dialog.confirm({
            title: "Error",
            message:
              "Hubo un error al guardar la presentación en la base de datos revise los campos obligatorios",
            confirmText: "Entendido",
            type: "is-danger",
            hasIcon: true,
            cancelText: "cancelar",
            canCancel: false,
          });
          return;
        }
        if (response === "Success") {
          this.$buefy.dialog.confirm({
            title: "Éxito",
            message: "Se guardó exitosamente la presentación",
            confirmText: "Entendido",
            type: "is-success",
            hasIcon: true,
            onConfirm: () => {},
            cancelText: "cancelar",
            canCancel: false,
          });
        } else {
          this.$buefy.dialog.confirm({
            title: "Error",
            message: "Hubo un error al guardar el artículo en la base de datos",
            confirmText: "Entendido",
            type: "is-danger",
            hasIcon: true,
            cancelText: "cancelar",
            canCancel: false,
          });
        }
      } catch (error) {
        this.$buefy.dialog.confirm({
          title: "Error",
          message:
            "Hubo un error al guardar la presentación en la base de datos",
          confirmText: "Entendido",
          type: "is-danger",
          hasIcon: true,
          cancelText: "cancelar",
          canCancel: false,
        });
      }
    },
    startImport() {
      this.$buefy.modal.open({
        canCancel: ["x"],
        parent: this,
        component: ImportFileModal,
        props: {
          importType: "presentations",
          nameImport: "Presentaciones",
          afterAction: "GETARTICLES",
        },
        hasModalCard: false,
        customClass: "import-provider-modal-class",
        trapFocus: true,
        width: "50%",
        fullScreen: false,
        destroyOnHide: true,
      });
    },
    startExport() {
      this.$buefy.dialog.confirm({
        title: "Confirmar exportación de presentaciones",
        message: `¿Desea confirmar la exportación de las presentaciones a un archivo csv?`,
        cancelText: "Cancelar",
        confirmText: "Confirmar",
        type: "is-success",
        onConfirm: () => this.exportData(),
      });
    },
    exportData() {
      const articlesPresentations = [];

      for (const singleArticle of this.articles) {
        for (const singlePresentation of singleArticle.PRESENTACIONES) {
          const singlePresentationFormatted = {};

          singlePresentationFormatted["CLAVE DEL ARTICULO (Requerido)"] =
            singleArticle.CLAVE_ART ? singleArticle.CLAVE_ART : "";

          singlePresentationFormatted["NOMBRE DE LA PRESENTACION (Requerido)"] =
            singlePresentation.NOMBRE ? singlePresentation.NOMBRE : "";

          singlePresentationFormatted["MEDIDA DE LA PRESENTACION (Requerido)"] =
            "";

          if (singlePresentation.MEDIDA) {
            for (const measure of this.measures) {
              if (measure._id.toString() === singlePresentation.MEDIDA) {
                singlePresentationFormatted[
                  "MEDIDA DE LA PRESENTACION (Requerido)"
                ] = measure.DESCRIP_MED;
              }
            }
          }

          singlePresentationFormatted[
            "ES MAYOR QUE EL ARTICULO BASE (Requerido)"
          ] = singlePresentation.ESMAYOR ? singlePresentation.ESMAYOR : "";

          singlePresentationFormatted[
            "EQUIVALENCIA DE LA MEDIDA BASE DE ARTICULO (Requerido)"
          ] = singlePresentation.FACTOR1 ? singlePresentation.FACTOR1 : "";

          singlePresentationFormatted[
            "EQUIVALENCIA DE LA PRESENTACION (Requerido)"
          ] = singlePresentation.FACTOR2 ? singlePresentation.FACTOR2 : "";

          articlesPresentations.push(singlePresentationFormatted);
        }
      }

      let payload = {
        data: articlesPresentations,
        name: "presentaciones-" + moment().format("l").toString(),
      };
      this.$store.dispatch("EXPORTCSV", payload);
    },
    async reloadInformation() {
      this.refreshLoading = true;

      let allPromises = [];

      allPromises.push(this.$store.dispatch("GETARTICLES"));

      await Promise.all(allPromises);

      this.refreshLoading = false;
    },
  },
  async created() {
    moment.locale("es");
    let allPromises = [];

    allPromises.push(this.$store.dispatch("GETBRANDS"));
    allPromises.push(this.$store.dispatch("GETMEASURES"));

    await Promise.all(allPromises);
  },
  watch: {
    selected(val) {
      if (val != null) {
        if (this.defaultOpenedDetails.includes(val.id)) {
          const index = this.defaultOpenedDetails.indexOf(val.id);
          this.defaultOpenedDetails.splice(index, 1);
        } else {
          this.defaultOpenedDetails.push(val.id);
        }
      }
    },
    defaultOpenedDetails() {
      this.selected = null;
    },
  },
  computed: {
    hint() {
      return this.$store.getters.HINT;
    },
    articles() {
      return this.$store.getters.ARTICLES.map((singleArticle) => {
        let articleWithProps = { ...singleArticle };
        return articleWithProps;
      });
    },
    measures() {
      return this.$store.getters.MEASURES;
    },
    companyPaymentPlanModules() {
      if (
        this.$store.getters.COMPANY_INFORMATION &&
        this.$store.getters.COMPANY_INFORMATION.paymentPlan
      ) {
        return this.$store.getters.COMPANY_INFORMATION.paymentPlan.modules;
      }
      return this.$store.getters.COMPANY_INFORMATION.paymentPlan.modules;
    },
    permissions() {
      if (this.$store.getters.USER.permissions) {
        return this.$store.getters.USER.permissions.permissions;
      }
      return this.$store.getters.USER.permissions;
    },
  },
};
</script>

<style scoped>
@import "../../Global/style/Global.css";
</style>
